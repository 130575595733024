import React from 'react';
import ReactDOM from 'react-dom/client';
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import App from './App';

// Sentry.init({
//   dsn: "https://d5ebcf3f63eb423d905e3b084c01e2d3@o1152318.ingest.sentry.io/4505313070219264",
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
//   environment: process.env.SENTRY_ENV
// })

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);