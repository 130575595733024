import { useEffect, useState } from 'react'

import styled from "styled-components";
import UXELogo from "./Tobii_UX_Explore_icon.svg";
import heroImage from "./w_Image_fallback_landing_page.jpg";
import appStoreLogo from "./Download_on_the_App_Store_logo.png"
import playStoreLogo from "./Google_Play_Store_badge_EN.svg.png"

import './index.css';


const ContentWrapper = styled.div`width: 100%; display: flex; justify-content: center; overflow: hidden;`;
const ContentCard = styled.div`width: calc((96px * 2) + 552px); background-color: #FFFFFF; text-align: center; display: flex; justify-content: center;`;
const ContentColumn = styled.div`width: 552px;`;
const DetailSpace = styled.div`width: 552px; padding: ${props => props.padding}; background: #F8F8F8; border: 1px solid #EBECF0; border-radius: 8px; display: flex; justify-content: center;`;
const DividerWrapper = styled.div`display: flex; justify-content: center;`;
const Divider = styled.div`width: 125px; height: 0px; border: 1px solid rgba(0, 0, 0, 0.24);`;

const SpaceX = styled.div`width: ${props => props.width}`;
const SpaceY = styled.div`height: ${props => props.height}`;


const App = () => {


  const [sixDigCode, setSixDigCode] = useState('')


  useEffect(() => {
    var queryParamsString = window.location.search.substr(1)

    if (queryParamsString.length > 0) {

      var link
      var androidDeepLinkPrefix = "https://oculidapp.page.link/?apn=com.oculid.oculid&link=";
      var uagent = navigator.userAgent.toLowerCase();
      var isAndroid = uagent.includes('android');
      var isIPhone = uagent.includes('iphone');

      if (isIPhone) {
        link = 'com.oculid://?'
      } else {
        link = 'https://www.oculid-test-key.com/?'
      } 

      var newQueryParamString = ""

      const queryParams = queryParamsString.split('&').reduce((accumulator, singleQueryParam) => {
        const [key, value] = singleQueryParam.split('=')
        accumulator[key] = value
        return accumulator
      }, {})

      var key;

      for (key in queryParams) {
        console.log(queryParams[key])
        newQueryParamString = newQueryParamString + key + "=" + queryParams[key] + "&"
      }

      const finalLink = link + queryParamsString
      const url = process.env.REACT_APP_URL_BASE + 'token/'

      fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: "",
          password: process.env.REACT_APP_PASSWORD,
          username: process.env.REACT_APP_USERNAME
        }),
      })
        .then(res => res.json())
        .then(resJSON => {

          let accessToken = resJSON.access;

          fetch(process.env.REACT_APP_URL_BASE + 'user-research/landingpage/', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'JWT ' + accessToken
            },
            body: JSON.stringify({ test_key: finalLink })
          })
            .then(res => res.json())
            .then(resJSON => { setSixDigCode(resJSON.code) })
        })
        .catch(err => { console.log("ERROR GETTING TOKEN: ", err) });

      if(isAndroid){
        var androidFinalLink = androidDeepLinkPrefix + link + newQueryParamString.replaceAll("&", "%26").replace("=","%3D");
        window.open(androidFinalLink, '_blank')
      }
    }
  }, [])


  return (
    <ContentWrapper>
      <ContentCard>
        <ContentColumn>
          <SpaceY height={"78px"} />

          <h3>{`Please Follow These Steps`}</h3>

          <SpaceY height={"34px"} />

          <DetailSpace padding={"40px 0px 16px 0px"}>
            <div>
              <div><img src={UXELogo} alt="oculid_logo" style={{ marginTop: "-22px" }} /></div>
              <SpaceY height={"32px"} />
              <div style={{ display: "flex" }}>
                <div><p>{`1. Install the app:`}</p></div>
                <SpaceX width={"4px"} />
                <div><p style={{ fontWeight: "800", fontFamily: "sans-serif" }}>{`'Tobii UX Explore'`}</p></div>
                <SpaceX width={"4px"} />
                <div><p>{`on your mobile phone`}</p></div>
              </div>
              <SpaceY height={"32px"} />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div><a href="https://apps.apple.com/de/app/oculid/id1599726402"><img style={{ width: "120px" }} src={appStoreLogo} alt="app_store_logo" /></a></div>
                <SpaceX width={"4px"} />
                <div><a href="https://play.google.com/store/apps/details?id=com.oculid.oculid&gl=DE"><img style={{ width: "120px" }} src={playStoreLogo} alt="app_store_logo" /></a></div>
              </div>
              <SpaceY height={"32px"} />
            </div>
          </DetailSpace>

          <SpaceY height={"28px"} />

          <DetailSpace padding={"52px 0px 52px 0px"}>
            <div>
              <p>{`2. Copy and paste this test-key:`}</p>
              <SpaceY height={"37px"} />
              <h2>{sixDigCode}</h2>
            </div>
          </DetailSpace>

          <SpaceY height={"28px"} />

          <img src={heroImage} alt="hero_image" style={{ width: "552px" }} />

          <SpaceY height={"58px"} />

          <h5>{`That is all you need to start testing!`}</h5>

          <SpaceY height={"44px"} />

          <DividerWrapper><Divider /></DividerWrapper>

          <SpaceY height={"44px"} />

          <h6>{`Any problems getting this done?`}</h6>
          <a href="mailto:support@oculid.com" target="_blank" rel="noreferrer">
            {`Contact us`}
          </a>

          <SpaceY height={"64px"} />

          <div style={{ filter: "brightness(0)", opacity: "0.4", padding: "0px 100px" }}>
          <p style={{ fontSize: "12px", fontWeight: "800" }}>{`Tobii UX Explore`}</p>
            <p style={{ fontSize: "12px" }}>{`Tobii AB (publ): Box 743 182 17 Danderyd Sweden`}</p>
            <p style={{ fontSize: "12px" }}>{`Copyright 2023`}</p>
          </div>

          <SpaceY height={"52px"} />

        </ContentColumn>
      </ContentCard>
    </ContentWrapper>
  );
}

export default App;
